import React from "react"
import Link from '../utils/link'

import Header from '../components/header'
import Seo from "../components/seo"

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" bodyClass='error404' />
    <Header />
    <section className='error404__section' data-color='#000'>
    	<div className='error404__inner'>
    		<div className='error404__content'>
			    <h1>404 Error: Page does not exist</h1>
			    <Link to='/' className='btn'>(back&hellip;)</Link>
			  </div>
		  </div>
		</section>
  </>
)

export default NotFoundPage
